export const environment = {
    name: "uat",
    production: false,
    rootAuthnUrl: "https://uat.authn.spectrumreach.io",
    adminPermissionName: "adminUIAccess",
    cognito: {
        customProviderName: "ESSO",
        Auth: {
            region: "us-east-1",
            userPoolId: "us-east-1_xExRGWhyz",
            userPoolWebClientId: "2obldl545fapuue3670j3aek06",
        },
        oauth: {
            domain: "srd-users-uat.auth.us-east-1.amazoncognito.com",
            redirectSignIn: "https://uat.admin.spectrumreach.io/",
            redirectSignOut: "https://uat.admin.spectrumreach.io/",
            responseType: "token",
        }
    },
    allowCognitoAuth: true
};